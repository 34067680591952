export const ACTIVITIES = [
  {
    id: "1",
    link: "https://www.geoguessr.com/",
    title: "GeoGuessr",
    description: "Find clues and guess where you are in the world.",
    category: "Game",
  },
  {
    id: "2",
    link: "http://www.higherlowergame.com",
    title: "Higher Lower Game",
    description: "Which Gets Googled More? Play The Higher Lower Game",
    category: "Game",
  },
  {
    id: "3",
    link: "https://tetr.io/",
    title: "Tetris",
    description:
      "TETR.IO is a modern yet familiar online stacker. Play against friends and foes all over the world.",
    category: "Game",
  },
  {
    id: "4",
    link: "https://papergames.io",
    title: "Paper Games IO",
    description:
      "Various online games such as Tic Tac Toe Online, Battleship, Connect 4 and Gomoku",
    category: "Game",
  },
  {
    id: "5",
    link: "https://virtualvacation.us/multiplayer",
    title: "Virtual Vacation",
    description: "Similar to GeoGuessr, with online Multiplayer",
    category: "Game",
  },
  {
    id: "6",
    link: "https://skribbl.io/",
    title: "Skribbl IO",
    description: "skribbl.io is a free multiplayer drawing and guessing game.",
    category: "Game",
  },
  {
    id: "7",
    title: "Tours of the home",
    description: "Show off your home on video call",
    category: "Video Tour",
  },
  {
    id: "8",
    title: "Tours of the workspace",
    description: "Show off your workspace on video call",
    category: "Video Tour",
  },
  {
    id: "9",
    title: "Tours of the neighborhood",
    description: "Show off your neighborhood on video call",
    category: "Video Tour",
  },
  {
    id: "10",
    title: "Just talking over a coffee/tea break",
    description:
      "Sit back, relax and have a chat about anything and everything",
    category: "Misc",
  },
];
