import { Button, Card, Icon, Label } from "semantic-ui-react";

function getIconFromCategory(category) {
  switch (category) {
    case "Game":
      return "game";
    case "Video Tour":
      return "video";
    default:
      return "talk";
  }
}

export function ActivityVotingCard({
  title,
  category,
  description,
  onVoteCallback,
  link,
  disableVoting,
  selected,
}) {
  return (
    <Card>
      <Card.Content>
        <Card.Header>{title}</Card.Header>
        <Card.Meta>
          <Label style={{ margin: "5px 0" }}>
            <Icon name={getIconFromCategory(category)} /> {category}
          </Label>
        </Card.Meta>
        <Card.Description>{description}</Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div style={{ display: "flex", justifyContent: "center", gap: "16px" }}>
          <Button
            icon
            labelPosition="left"
            color={selected ? "red" : "green"}
            onClick={onVoteCallback}
            disabled={disableVoting}
            circular
          >
            <Icon name={selected ? "close" : "thumbs up outline"} />
            {selected ? "Remove" : "Vote"}
          </Button>
          {link && (
            <Button
              as="a"
              icon
              color="violet"
              labelPosition="left"
              href={link}
              target="_blank"
              circular
            >
              <Icon name="linkify" />
              Visit
            </Button>
          )}
        </div>
      </Card.Content>
    </Card>
  );
}
