import { Button, Card, Icon, Label } from "semantic-ui-react";

function getIconFromCategory(category) {
  switch (category) {
    case "Game":
      return "game";
    case "Video Tour":
      return "video";
    default:
      return "talk";
  }
}

export function ActivityDisplayCard({
  title,
  category,
  description,
  link,
  focused,
  onFocusCallback
}) {
  return (
    <Card>
      <Card.Content>
        <Card.Header>{title}</Card.Header>
        <Card.Meta>
          <Label style={{ margin: "5px 0" }}>
            <Icon name={getIconFromCategory(category)} /> {category}
          </Label>
        </Card.Meta>
        <Card.Description>{description}</Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div style={{ display: "flex", justifyContent: "center", gap: "16px" }}>
        {link && (<>
          <Button
              icon
              color="orange"
              onClick={() => onFocusCallback(link)}
              disabled={focused}
              circular
            >
            <Icon name={'arrow up'}/>
        </Button>
          <Button
            as="a"
            icon
            color="violet"
            labelPosition="left"
            href={link}
            target="_blank"
            circular
          >
            <Icon name="linkify" />
            Visit
          </Button>
        </>)}
        </div>
      </Card.Content>
    </Card>
  );
}
