import { useEffect, useState } from "react";
import { Icebreakers, Voting, Homepage } from "./views";
import "semantic-ui-css/semantic.min.css";
import "./App.css";
import io from "socket.io-client";
import { ACTIVITIES } from "./data/activities";

function App() {
  const socket = io.connect("http://localhost:8000/", {
    transportOptions: {
      polling: {
        extraHeaders: {
          "my-custom-header": "abcd",
        },
      },
    },
  });

  const [currentView, setCurrentView] = useState("index");
  const [currentViewComponent, setCurrentViewComponent] = useState();
  const [convoStarter, setConvoStarter] = useState(false);
  const [roomName, setRoomName] = useState("");
  const [voteResults, setVoteResults] = useState([]);
  const [topic, setTopic] = useState("Ready for some icebreakers?");
  const [color, setColor] = useState("");

  useEffect(() => {
    const Component = routes[currentView];
    setCurrentViewComponent(
      <Component
        roomName={roomName}
        setRoomName={setRoomName}
        socket={socket}
        setConvoStarter={setConvoStarter}
        convoStarter={convoStarter}
        setCurrentView={setCurrentView}
        voteResults={voteResults}
        topic={topic}
        color={color}
      />
    );
  }, [currentView, roomName, voteResults, topic, color]);

  useEffect(() => {
    socket.on("voteResults", ({ votes }) => {
      const winningActivites = [];
      votes.forEach((vote) => {
        ACTIVITIES.forEach((activity) => {
          if (activity.id === vote) {
            winningActivites.push(activity);
          }
        });
      });
      setVoteResults(winningActivites);
      setCurrentView("icebreakers");
    });
    socket.on("newTopicResult", ({ topic, color }) => {
      setTopic(topic);
      setColor(color);
    });
  }, []);

  const routes = {
    voting: Voting,
    icebreakers: Icebreakers,
    index: Homepage,
  };

  return <>{currentViewComponent}</>;
}

export default App;
