import React, { useState, useEffect } from "react";
import { Container, Card, Header, Button } from "semantic-ui-react";
import { ACTIVITIES } from "../data/activities";
import { ActivityVotingCard } from "../components/ActivityVotingCard";
import styled from "styled-components";

const StyledBackground = styled.div`
  background-color: #141415;
  min-height: 100vh;
  padding: 0 16px;

  h1.header {
    color: white;
    margin-bottom: 32px;
    text-align: center;
  }
`;

const GAME_VOTE_LIMIT = 3;

export default function Voting({ socket, roomName }) {

  const [selectedActivities, setSelectedActivities] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  function handleVoteCallback(activityId) {
    if (selectedActivities.includes(activityId)) {
      setSelectedActivities(
        selectedActivities.filter((id) => id !== activityId)
      );
      return;
    }

    setSelectedActivities([...selectedActivities, activityId]);
  }

  useEffect(() => {
  },[])

  const submitVote = () => {
    setSubmitted(true);
    socket.emit("vote", {
      activities: selectedActivities,
      roomName: roomName
    })
  }

  return (
    <StyledBackground>
      <Container style={{ paddingTop: "40px" }}>
        <Header as="h1">
          {`Vote for Activities - You have ${
            GAME_VOTE_LIMIT - selectedActivities.length
          } votes remaining`}
        </Header>
        <Card.Group style={{ justifyContent: "center" }}>
          {ACTIVITIES.map((activity) => {
            const isVotingDisabled =
              GAME_VOTE_LIMIT - selectedActivities.length === 0 &&
              !selectedActivities.includes(activity.id);

            return (
              <ActivityVotingCard
                disableVoting={isVotingDisabled}
                selected={selectedActivities.includes(activity.id)}
                key={activity.id}
                title={activity.title}
                description={activity.description}
                category={activity.category}
                onVoteCallback={() => handleVoteCallback(activity.id)}
                link={activity.link}
              />
            );
          })}
        </Card.Group>
        <div style={{ textAlign: "center" }}>
          <Button
            onClick={submitVote}
            color="violet"
            size="big"
            disabled={selectedActivities.length === 0 || submitted}
            style={{ margin: "40px auto 20px" }}
            circular
          >
            {submitted ? 'Waiting for friend' : `Submit ${selectedActivities.length} votes`}
          </Button>
        </div>
      </Container>
    </StyledBackground>
  );
}
