import { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Card, Input, Statistic } from "semantic-ui-react";
import logo from "../assets/logo.png";

const StyledBackground = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #141415;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export default function Homepage({
  setConvoStarter,
  roomName,
  setRoomName,
  socket,
  convoStarter,
  setCurrentView,
}) {
  const [currentStep, setCurrentStep] = useState("start");
  const [roomNameValue, setRoomNameValue] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    socket.on("startKiki", () => {
      console.log("kiki started");
      setCurrentView("voting");
    });
  }, []);

  const setConvo = (length = 7) => {
    console.log(length);
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setRoomName(result);
    setConvoStarter(true);
    socket.emit("joinRoom", { convoStarter: true, roomName: result });
  };

  const joinConvo = () => {
    socket.emit("joinRoom", { convoStarter: false, roomName: roomNameValue });
  };

  const startKiki = () => {
    setConvo();
    setCurrentStep("viewRoom");
  };

  const joinKiki = () => {
    setLoading(true);
    setRoomName(roomNameValue);
    joinConvo();
  };

  const copyRoomCode = () => {
    navigator.clipboard.writeText(roomName);
  };

  const Start = () => {
    return (
      <Card
        style={{
          padding: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "400px",
        }}
      >
        <Card.Content>
          <Card.Description>Time to make some new friends!</Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Button circular color="orange" onClick={startKiki}>
            Start a kiki
          </Button>
          <Button
            circular
            color="violet"
            onClick={() => setCurrentStep("joinRoom")}
          >
            Join a kiki
          </Button>
        </Card.Content>
      </Card>
    );
  };

  const JoinRoom = () => {
    return (
      <Card
        style={{
          padding: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "400px",
        }}
      >
        <Card.Content>
          <Card.Description>Paste your room code!</Card.Description>
          <Input
            loading={loading}
            placeholder="Room code"
            value={roomNameValue}
            onChange={(e) => setRoomNameValue(e.target.value)}
          />
        </Card.Content>
        <Card.Content extra>
          <Button circular color="violet" onClick={joinKiki} disabled={loading}>
            Join kiki
          </Button>
        </Card.Content>
      </Card>
    );
  };

  const ViewRoom = ({ roomName }) => {
    return (
      <Card
        style={{
          padding: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "400px",
        }}
      >
        <Card.Content>
          <Card.Description>
            Share your room code with your partner
          </Card.Description>
          {roomName && (
            <Statistic>
              <Statistic.Value>{roomName}</Statistic.Value>
            </Statistic>
          )}
        </Card.Content>
        <Card.Content extra>
          <Button circular color="violet" onClick={copyRoomCode}>
            Copy room code
          </Button>
        </Card.Content>
      </Card>
    );
  };

  const steps = {
    start: <Start />,
    joinRoom: <JoinRoom />,
    viewRoom: <ViewRoom roomName={roomName} />,
  };

  return (
    <StyledBackground>
      <img style={{ height: "400px", width: "auto" }} alt="logo" src={logo} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 style={{ color: "white" }}>Welcome to let's kiki!</h1>
        {steps[currentStep]}
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        ></div>
      </div>
    </StyledBackground>
  );
}
