import { useEffect, useState } from "react";
import { Card, Header, Button } from "semantic-ui-react";
import styled from "styled-components";
import { ActivityDisplayCard } from '../components/ActivityDisplayCard'

const StyledStoriesPage = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  background-color: #141415;
  display: flex;
  padding: 15px;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const StyledIframe = styled.iframe`
  flex-grow: 1; 
  border: none; 
  margin-top: 20px; 
  padding: 0;
  border-radius: 15px;
  height: 600px
`

const StyledStoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
`;

const StyledStoryWrapper = styled.div`
  width: 360px;
  height: 640px;
  border-radius: 25px;
  padding: 20px;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 0;
  background-color: ${props => props.color};
`
export default function Icebreakers({ socket, convoStarter, roomName, topic, color, voteResults }) {

  const [focusedLink, setFocusedLink] = useState('')

  useEffect(() => {
    if(convoStarter){
      console.log('newTopic')
      socket.emit("newTopic", {
        roomName: roomName,
      });
    }

  }, [])


  return (
    <StyledStoriesPage>
      <StyledStoriesContainer>
        <h2 style={{color: 'white'}}>Conversation starters:</h2>
        {topic && <StyledStoryWrapper color={color}>
          <h3>{topic}</h3>
        </StyledStoryWrapper>}
        <div style={{ display: "flex", alignItems: "center", paddingTop: "10px" }}>
          <Button
            circular
            color="violet"
            content="Next"
            icon="right arrow"
            labelPosition="right"
            onClick={() => socket.emit("newTopic", ({roomName: roomName}))}
          />
        </div>
      </StyledStoriesContainer>
      <div style={{color: 'white', textAlign: 'center'}}>
          {focusedLink && <StyledIframe title={focusedLink} src={focusedLink} width="100%" ></StyledIframe>}
        <h1>
          Activities you both voted for
        </h1>
        <Card.Group style={{ justifyContent: "center" }}>
          {voteResults.map((activity) => {
            return (
              <ActivityDisplayCard
                key={activity.id}
                title={activity.title}
                description={activity.description}
                category={activity.category}
                link={activity.link}
                onFocusCallback={setFocusedLink}
                focused={focusedLink === activity.link}
              />
            );
          })}
        </Card.Group>
      </div>
    </StyledStoriesPage>
  );
}
